import React, { useState } from 'react';
import { AlertCircle, Check, Loader2, Edit, Trash } from 'lucide-react';

const WebsiteSourceForm = ({ chatbotId, fetchChatbotData }) => {
  const [url, setUrl] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [fetchedData, setFetchedData] = useState('');
  const [showFetchedData, setShowFetchedData] = useState(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [crawlableLinks, setCrawlableLinks] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [isScraping, setIsScraping] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [progress, setProgress] = useState(0); // new progress state

  const scrapeUrl = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/basic_scraper`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.text) {
          setFetchedData(`Source URL: ${url}\n${data.text}`);
          setBaseUrl(url);
        }
        setSocialMediaLinks(data.links.social_media);
        setCrawlableLinks(data.links.crawlable);
        setShowFetchedData(true);
      } else {
        setError('Failed to fetch data. Please check the URL and try again.');
      }
    } catch (error) {
      setError('Please ensure you using a website that begins with http:// or https://');
    } finally {
      setIsLoading(false);
    }
  };

  const maxLinks = 5;

  const toggleLinkSelection = (link) => {
    const newSelectedLinks = new Set(selectedLinks);
    if (newSelectedLinks.has(link)) {
      newSelectedLinks.delete(link);
    } else {
      newSelectedLinks.add(link);
    }
    setSelectedLinks(newSelectedLinks);
  };

  const selectAllLinks = () => {
    const limitedLinks = crawlableLinks.slice(0, maxLinks);
    setSelectedLinks(new Set(limitedLinks));
  };

  const deselectAllLinks = () => {
    setSelectedLinks(new Set());
  };

  const scrapeSingleLink = async (link) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/basic_scraper`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: link }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.text) {
          setFetchedData((prevData) => `${prevData}\n\nSource URL: ${link}\n${data.text}`);
        }
      }
    } catch (error) {
      setError(`Failed to scrape ${link}`);
    }
  };

  // Updated scrapeSelectedLinks with progress tracking
  const scrapeSelectedLinks = async () => {
    setIsScraping(true);
    setProgress(0);
    const totalLinks = selectedLinks.size;
    const sanitizedBaseUrl = baseUrl.replace(/\/+$/, '');

    let completed = 0;
    for (const link of selectedLinks) {
      const fullLink = `${sanitizedBaseUrl}/${link}`;
      await scrapeSingleLink(fullLink);
      completed++;
      setProgress(Math.round((completed / totalLinks) * 100));
    }
    setIsScraping(false);
    // Clear the selectedLinks state to hide the button
    setSelectedLinks(new Set());
  };

  const removeSocialMediaLink = (index) => {
    setSocialMediaLinks(socialMediaLinks.filter((_, i) => i !== index));
  };

  const modifySocialMediaLink = (index, newLink) => {
    const updatedLinks = [...socialMediaLinks];
    updatedLinks[index] = newLink;
    setSocialMediaLinks(updatedLinks);
  };

  const saveScrapedData = async () => {
    setIsSaving(true);
    setStatusMessage('');
    const formData = new FormData();
    formData.append('chatbot_id', chatbotId);
    formData.append('name', url);
    formData.append(
      'website',
      `${fetchedData}\n\nSocial Media Links:\n${socialMediaLinks.join('\n')}`
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API}/chatbots/addKnowledgeSource`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      fetchChatbotData();
      setStatusMessage('Scraped data saved successfully.');
    } catch (error) {
      console.error('Error saving scraped data:', error);
      setStatusMessage('Error saving scraped data.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="w-full max-w-4xl bg-gray-900 text-gray-100 rounded-lg shadow-lg">
      <div className="p-6">
        <h2 className="text-xl font-bold mb-6">Website Scraper</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="url" className="block text-sm font-medium mb-2">
              Enter the URL to scrape
            </label>
            <div className="flex gap-2">
              <input
                type="url"
                id="url"
                placeholder="https://example.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="flex-1 rounded bg-gray-800 border border-gray-700 p-2 text-gray-100 focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={scrapeUrl}
                disabled={isLoading}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
              >
                {isLoading ? <Loader2 className="w-4 h-4 animate-spin" /> : 'Scrape'}
              </button>
            </div>
          </div>

          {error && (
            <div className="bg-red-900/50 border border-red-800 text-red-100 px-4 py-3 rounded flex items-center gap-2">
              <AlertCircle className="h-4 w-4" />
              <p>{error}</p>
            </div>
          )}

          {showFetchedData && (
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium mb-2">Scraped Content</h3>
                <textarea
                  value={`${fetchedData}\n\nSocial Media Links:\n${socialMediaLinks.join('\n')}`}
                  readOnly
                  className="w-full h-32 rounded bg-gray-800 border border-gray-700 p-2 text-gray-100"
                />
              </div>

              {crawlableLinks.length > 0 && (
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-medium">Crawlable Links</h3>
                    <div className="space-x-2">
                      <button
                        onClick={selectAllLinks}
                        className="px-2 py-1 text-sm bg-gray-700 rounded hover:bg-gray-600"
                      >
                        Select All
                      </button>
                      <button
                        onClick={deselectAllLinks}
                        className="px-2 py-1 text-sm bg-gray-700 rounded hover:bg-gray-600"
                      >
                        Deselect All
                      </button>
                    </div>
                  </div>
                  <p className="text-xs text-gray-400 mb-2">
                    You can only scrape up to 5 pages at a time to ensure your AI agents' responses are accurate.
                    If you wish to scrape the complete website, <a href="/contact" className="text-blue-500 hover:underline">contact us</a> to get an enterprise plan.
                  </p>
                  <div className="grid gap-2 max-h-64 overflow-y-auto">
                    {crawlableLinks.map((link, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 p-2 rounded bg-gray-800 hover:bg-gray-700 cursor-pointer"
                        onClick={() => toggleLinkSelection(link)}
                      >
                        <div className="w-6 h-6 flex items-center justify-center rounded border border-gray-600">
                          {selectedLinks.has(link) && <Check className="w-4 h-4 text-blue-500" />}
                        </div>
                        <span className="flex-1 truncate">{link}</span>
                      </div>
                    ))}
                  </div>
                  {selectedLinks.size > 0 && (
                    <>
                      <button
                        onClick={scrapeSelectedLinks}
                        disabled={isScraping}
                        className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 flex items-center justify-center gap-2"
                      >
                        {isScraping ? (
                          <>
                            <Loader2 className="w-4 h-4 animate-spin" />
                            <span>Scraping selected links...</span>
                          </>
                        ) : (
                          <span>Scrape {selectedLinks.size} selected links</span>
                        )}
                      </button>
                      {isScraping && (
                        <div className="w-full bg-gray-700 rounded-full mt-2">
                          <div
                            className="bg-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
                            style={{ width: `${progress}%` }}
                          >
                            {progress}%
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {socialMediaLinks.length > 0 && (
                <div>
                  <h3 className="text-lg font-medium mb-2">Social Media Links</h3>
                  <div className="space-y-2">
                    {socialMediaLinks.map((link, index) => (
                      <div key={index} className="flex items-center gap-2 p-2 rounded bg-gray-800">
                        <input
                          type="text"
                          value={link}
                          onChange={(e) => modifySocialMediaLink(index, e.target.value)}
                          className="flex-1 rounded bg-gray-700 border border-gray-600 p-2 text-gray-100"
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeSocialMediaLink(index);
                          }}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <button
                onClick={saveScrapedData}
                disabled={isSaving}
                className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-green-700 disabled:opacity-50 flex items-center justify-center gap-2"
              >
                {isSaving ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <span>Saving scraped data...</span>
                  </>
                ) : (
                  <span>Save Scraped Data</span>
                )}
              </button>

              {statusMessage && (
                <p className="text-center text-white text-sm p-4">{statusMessage}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebsiteSourceForm;