import React, { useState, useEffect } from 'react';
import {
  GlobeAltIcon,
  DocumentIcon,
  ChatBubbleBottomCenterTextIcon,
  LockClosedIcon,
  CloudArrowUpIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../context/AuthContext';
import { fetchSubscriptionStatus } from '../../services/api';
import WebsiteSourceForm from '../molecules/WebsiteSourceForm';

const AddKnowledgeSourceForm = ({chatbotId, fetchChatbotData }) => {
  const [sourceType, setSourceType] = useState('Website');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [knowledgeSourceId, setKnowledgeSourceId] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessToken, user } = useAuth();
  const [dragActive, setDragActive] = useState(false);
  const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileSizeWarning, setFileSizeWarning] = useState('');

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (['dragenter', 'dragover'].includes(e.type)) {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);

      // Update the file input element's value
      const fileInput = document.getElementById('document');
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(droppedFile);
      fileInput.files = dataTransfer.files;

      setStatusMessage(`File selected: ${droppedFile.name}`);
      checkFileSize(droppedFile.size);
    }
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setStatusMessage(`File selected: ${selectedFile.name}`);
    checkFileSize(selectedFile.size);
  };

  const checkFileSize = (size) => {
    const sizeInMB = size / (1024 * 1024);
    if (sizeInMB > 50) {
      setFileSizeWarning('File size exceeds 50 MB. Please select a smaller file.');
    } else {
      setFileSizeWarning('');
    }
  };

  useEffect(() => {
    const setSubscriptionStatus = async () => {
      try {
        if (user) {
          const userSubscription = await fetchSubscriptionStatus(user.sub);
          setSubscriptionType(userSubscription.subscription_type);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setSubscriptionType('free_trial');
      } finally {
        setIsLoading(false);
      }
    };

    setSubscriptionStatus();
  }, [user]);

  const updateChatbotData = async (chatbot_id, data) => {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_REST_API}/chatbots/${chatbot_id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to update chatbot. Status: ${response.status}. Error: ${errorData.detail}`
        );
      }

      const responseData = await response.json();
      console.log('Chatbot updated successfully:', responseData);
    } catch (error) {
      console.error('Error updating chatbot:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (fileSizeWarning) {
      setStatusMessage('Please select a file smaller than 50 MB.');
      return;
    }
  
    setLoading(true);
    setStatusMessage('');
  
    const formData = new FormData();
    formData.append('chatbot_id', chatbotId);
    formData.append('name', name);
  
    try {
      if (sourceType === 'Document') {
        const documentFormData = new FormData();
        documentFormData.append('documents', file);
  
        const parseResponse = await fetch(
          `${process.env.REACT_APP_REST_API}/chatbots/parseDocumentContent`,
          {
            method: 'POST',
            body: documentFormData,
          }
        );
  
        if (!parseResponse.ok) {
          throw new Error(`HTTP error! status: ${parseResponse.status}`);
        }
  
        const parseData = await parseResponse.json();
        formData.append('document', parseData.content);
        formData.append('name', file.name);
      } else if (sourceType === 'Text Prompt') {
        formData.append('text_prompt', text);
      } else if (sourceType === 'Website') {
        formData.append('url', url);
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_REST_API}/chatbots/addKnowledgeSource`,
        {
          method: 'POST',
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const { knowledge_source_id } = data;
  
      await updateChatbotData(chatbotId, { knowledgeSourceSet: true });
  
      setKnowledgeSourceId(knowledge_source_id);
  
      resetForm();
  
      setStatusMessage('Knowledge source added successfully.');
      fetchChatbotData(); // Trigger the reload functionality
    } catch (error) {
      console.error('Error adding knowledge source:', error);

    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setSourceType('');
    setName('');
    setText('');
    setFile(null);
    setUrl('');
    setKnowledgeSourceId(null);
  };

  const handleSourceTypeClick = (type) => {
    if (type === 'Website' && subscriptionType !== 'business') {
      setShowSubscriptionMessage(true);
      return;
    }
    setSourceType(type);
    setShowSubscriptionMessage(false);
  };

  if (isLoading) {
    return (
      <p className="text-center py-4 text-gray-300">
        Processing, please check back in 2-3 minutes.
      </p>
    );
  }       const sourceTypeIcons = {
          'Website': <GlobeAltIcon className="h-5 w-5" />,
          'Document': <DocumentIcon className="h-5 w-5" />,
          'Text Prompt': <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
        };

  return (
    <form onSubmit={handleSubmit} className="my-4 relative">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-100">
            Choose Knowledge Source Type:
          </label>
             <select
          value={sourceType}
          onChange={(e) => handleSourceTypeClick(e.target.value)}
          className="border border-gray-100 rounded-md text-md font-medium text-gray-100 bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 w-full py-2 mt-1 pl-4"
        >
          {['Website', 'Document', 'Text Prompt'].map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        </div>
     

      {sourceType === 'Website' && (
        <WebsiteSourceForm chatbotId={chatbotId} fetchChatbotData={fetchChatbotData}/>
      )}

           {sourceType === 'Document' && (
        <div className="mb-4 bg-gray-800 p-4 rounded">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-100">Source Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-gray-900 text-gray-100 pl-4 mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2"
              placeholder="Enter a name for the source"
              required
            />
          </div>
          <label htmlFor="document" className="block text-sm font-medium text-gray-100">
            Upload Document
          </label>
          <div
            className={`relative mt-1 block w-full ${
              dragActive ? 'border-dashed border-2 border-gray-400' : ''
            }`}
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="document"
              onChange={handleChange}
              className="hidden"
              required
            />
            <label
              htmlFor="document"
              className="flex flex-col items-center justify-center py-2 px-4 rounded-full border border-gray-100 text-sm font-semibold text-gray-100 cursor-pointer hover:bg-gray-700"
            >
              <CloudArrowUpIcon className="h-10 w-10 margin-auto" />
              <p className="text-sm">{file ? file.name : 'Choose File'}</p>
            </label>
          </div>
          {file && (
            <div className="mt-2">
              <p className="text-sm text-gray-100">
                File Size: {(file.size / (1024 * 1024)).toFixed(2)} MB
              </p>
              {fileSizeWarning && (
                <p className="text-red-500">{fileSizeWarning}</p>
              )}
            </div>
          )}
          {sourceType && !loading && (
            <div className="mt-6">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-100 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Source
              </button>
            </div>
          )}
        </div>
      )}

           {sourceType === 'Text Prompt' && (
        <div className="mb-4 bg-gray-800 p-4 rounded">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-100">Source Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-gray-900 text-gray-100 pl-4 mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2"
              placeholder="Enter a name for the source"
              required
            />
          </div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-100">
            Text
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-600 bg-gray-900 text-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2"
            required
          />
          {sourceType && !loading && (
            <div className="mt-6">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-100 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Source
              </button>
            </div>
          )}
        </div>
      )}

      {loading && (
        <div className="items-center justify-center bg-gray-900 rounded-lg">
          <p className="text-center p-4 text-sm">
              Processing and embedding your knowledge source, this may take a a few minutes you may leave this page and come back.
            </p>
          <div className="flex items-center justify-center pb-6 ">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500">
          </div>
        </div>
       </div>
       
      )}
      {statusMessage && (
        <p className="text-center text-white text-sm p-4">{statusMessage}</p>
      )}

  
    </form>
  );
};

export default AddKnowledgeSourceForm;